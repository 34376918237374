<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="名称" prop="name" :rules="rules">
        <el-input style="width: 400px" v-model="formData.name" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="描述">
        <el-input style="width: 400px" type="textarea" v-model="formData.description" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDetailAPI, addAPI, editAPI } from './api'

export default {
  name: 'AddOrEdit',

  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        name: '',
        description: ''
        
      }
    }
  },

  mounted() {
    this.getDictList()
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
    },
    async getDictList() {
      const res = await getStationListAPI()
      this.station_list = res.data || []
      const result = await getDictEnumListAPI('charging_gun_status')
      this.gun_status_list = result.map((item) => {
        return {
          name: item.name,
          value: item.value - 0
        }
      })

      const { data } = await getPileListAPI()
      this.pile_list = data || []
    }
  }
}
</script>

<style lang="scss" scoped></style>
